import React from 'react';
import { Link as DomLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './styles.scss';
import { scrollWidthOffset } from '../../helpers/utils'
import { Link, RichText } from '@sitecore-jss/sitecore-jss-react';


const Footer = ({ fields }) => {

    return (
        <div className="include-footer">
            <div className="include-footer-wrapper">
                <div className="inc-footer-column">
                    <div className="inc-footer-column-header"> Contact </div>
                    <RichText className="contact-text" field={fields.address} />
                    <div className="links contact-text">
                        <div className="icon-label">
                            <div>
                                <i className="far fa-envelope"></i>
                            </div>
                            <Link className="pl-10" field={fields.emailAddress} />
                        </div>
                        <div className="icon-label">
                            <div>
                                <i className="fas fa-mobile-alt"></i>
                            </div>
                            <Link className="pl-10" field={fields.phoneNumber} />
                        </div>
                    </div>
                </div>
                <div className="inc-footer-column site-map-mobile">
                    <div className="inc-footer-column-header"> Legal </div>
                    <div className="site-map-links contact-text">
                        <DomLink to="/terms">Terms & conditions</DomLink>
                        <DomLink to="/privacy">Privacy statement</DomLink>
                        <HashLink smooth to="/privacy#cookies" scroll={el => scrollWidthOffset(el)}>Cookie policy</HashLink>
                    </div>

                </div>
                <div className="inc-footer-column social-mobile">
                    <div className="inc-footer-column-header"> Social </div>
                    <div className="social social-mobile contact-text">
                        <div className="icon-label">
                            <div className="linkedin-ico" />
                            <a href="https://www.linkedin.com/company/includeagency/about/" rel="noreferrer" target="_blank">LinkedIn</a>
                        </div>
                        <div className="icon-label" style={{display: 'none'}}>
                            <div className="facebook-ico" />
                            <DomLink to="">Facebook</DomLink>
                        </div>
                        <div className="icon-label" style={{display: 'none'}}>
                            <div className="twitter-ico" />
                            <DomLink to="">Twitter</DomLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right">Copyright © {new Date().getFullYear()}. Include Agency, Denmark. All rights reserved</div>
        </div>
    )
}

export default Footer;
