import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import IncludeButton from '../Button';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { contentButtonIconColor, contentTextColor, ScreenTypes } from '../../constants';
import { useHistory } from 'react-router-dom';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const PromoCardBlock = (props) => {
    const [isDesktop, setIsDesktop] = useState(false);
    const [isTabletLand, setIsTabletLand] = useState(false);
    const [isTabletPort, setIsTabletPort] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [imageHeight, setImageHeight] = useState(0);
    const [useBackgroundImage, setUseBackgroundImage] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('transparent');

    const {
        promoImageDesktop,
        promoImageTabletLand,
        promoImageTabletPort,
        promoImageMobile,
        isImageRight = true,
        header,
        text,
        TextColor,
        componentBackgroundColor,
        componentBackgroundImage = null,
        butonTextColor,
        linkTo,
        linkText,
        btnIconColor = contentButtonIconColor,
        useFullWidth = false,
        btnBackgroundColor
    } = props.fields;

    const imageRef = useRef(null);
    const history = useHistory();

    console.log(props.fields);
    useEffect(() => {
        // Update background color when componentBackgroundColor changes
        if (componentBackgroundColor?.value) {
            setBackgroundColor(componentBackgroundColor.value);
        } else {
            setBackgroundColor('transparent');
        }
    }, [componentBackgroundColor]);

    // Reset state on mount to ensure fresh state when navigating back to this page
    useEffect(() => {
        if (componentBackgroundImage?.value?.src) {
            setUseBackgroundImage(true);
        } else {
            setUseBackgroundImage(false);
        }
    }, [componentBackgroundImage]);

    // Handle image load event to set image height
    useEffect(() => {
        const handleImageLoad = () => {
            if (imageRef.current) {
                setImageHeight(imageRef.current.offsetHeight);
            }
        };

        const imgElement = imageRef.current;
        if (imgElement && imgElement.complete) {
            handleImageLoad();
        } else if (imgElement) {
            imgElement.addEventListener('load', handleImageLoad);
            return () => {
                imgElement.removeEventListener('load', handleImageLoad);
            };
        }
    }, [promoImageDesktop]);

    // Calculate screen type on load and resize
    useEffect(() => {
        const calculateScreenType = () => {
            const screenWidth = window.innerWidth;
            setIsDesktop(screenWidth > ScreenTypes.desktop);
            setIsTabletLand(screenWidth <= ScreenTypes.desktop && screenWidth >= ScreenTypes.tabletPort);
            setIsTabletPort(screenWidth < ScreenTypes.tabletPort && screenWidth >= ScreenTypes.mobile);
            setIsMobile(screenWidth < ScreenTypes.mobile);
        };

        calculateScreenType(); // Initial calculation

        window.addEventListener('resize', calculateScreenType); // Listen for resize events

        return () => {
            window.removeEventListener('resize', calculateScreenType); // Cleanup listener on unmount
        };
    }, []);

    const backGroundimageUrl = useBackgroundImage ? componentBackgroundImage.value.src : '';
    const componentStyle = {
        color: TextColor?.value || 'inherit',
        backgroundColor: useBackgroundImage ? null : backgroundColor,
        background: useBackgroundImage ? `url(${backGroundimageUrl})` : null,
        backgroundSize: useBackgroundImage ? 'cover' : '',
    };

    const handleButtonClick = () => {
        if (linkTo?.value) {
            if (linkTo.value.linktype === 'external') {
                if (linkTo.value.target === '_blank') {
                    window.open(linkTo.value.href, '_blank');
                } else {
                    window.location.href = linkTo.value.href;
                }
            } else {
                history.push(linkTo.value.href);
            }
        }
    };

    return (
        <div
            className={`m-b-50 m-t-55 ${isImageRight ? 'promo-card-right' : 'promo-card-left'} ${
                useFullWidth ? 'useFullWidth' : ''
            }`}
            style={componentStyle}
        >
            <div className="card-content m-t-b-90">
                <div className="promo-text-wrapper">
                    <div className="header-row">
                        <div className="promo-heading">
                            <Text field={header} />
                        </div>
                    </div>
                    <div className="card-text m-b-50 pr-26">
                        <RichText field={text} />
                    </div>
                    {linkTo?.value?.href && (
                        <div className="learn-more-btn">
                            <IncludeButton
                                text={linkText.value}
                                textColor={butonTextColor?.value || contentTextColor}
                                buttonBackgroundColor={btnBackgroundColor && btnBackgroundColor.value}
                                height="56px"
                                iconColor={btnIconColor || contentButtonIconColor}
                                onClick={handleButtonClick}
                            />
                        </div>
                    )}
                </div>
                <div className="promo-img-wrapper">
                    {isDesktop && <Image field={promoImageDesktop} className="promo-img isDesktop" />}
                    {isTabletLand && <Image field={promoImageTabletLand} className="promo-img isTabletLand" />}
                    {isTabletPort && <Image field={promoImageTabletPort} className="promo-img isTabletPort" />}
                    {isMobile && <Image field={promoImageMobile} className="promo-img isMobile" />}
                </div>
            </div>
        </div>
    );
};

export default PromoCardBlock;
