import React, { useState, useRef, useEffect } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './styles.scss';
import {NavLink, useLocation, useHistory } from 'react-router-dom';

const NestedMenu = (props) => {
    const  {
        route
    } = props

    // store second level route items
    const [subList, setSubList] = useState([]);
    // store menu offset position
    const [menuOffset, setMenuOffset] = useState(0);
    // store menu original offset width
    const [originalOffsetWidth, setOriginalOffsetWidth] = useState(0);
    // store original dropdown position
    const [originalPosition, setOriginalPosition] = useState(0);
    // store reference to a parent Route Item
    const [parentItem, setParentItem] = useState({})
    // store Current selected route
    const [activeItem, setActiveItem] = useState({})
    // get reference to a drop down element
    const menuDropDown = useRef(null);
    // get current route location object
    let location = useLocation();
    // get router history effect
    const history = useHistory();

    /**
     * Recursively find active route within the route map tree
     * @param route
     * @param parent
     */
    const findActiveRoute = (route, parent) => {
        Object.keys(route).forEach( key => {
            if(route[key] === location.pathname) {
                setActiveItem({route, parent})
            } else {
                if(route[key] instanceof Array) {
                    route[key].forEach( item => {
                        findActiveRoute(item, route)
                    })
                }
            }
        })
    }
    /**
     * Get first level Menu active state
     * @param route
     * @return {T[]}
     */
    const setParentActive = (route) => {
       return  route.items.filter( i => i.link === location.pathname)
    }

    /**
     * Event handler to opne menu and calculate its position
     * @param e
     * @return {boolean}
     */
    const handleOpenMenu = (e) => {
        const target = e.target;
        // original dropdown position
        const widthToShift = target.offsetLeft - ((menuDropDown.current.offsetWidth / 2) - (target.offsetWidth / 2));
        // store dropdown width
        setOriginalOffsetWidth(target.offsetWidth)
        // store dropdown origin position
        setOriginalPosition(widthToShift)
        // set Dropdown current position
        setMenuOffset(widthToShift)
    }

    /**
     * Handle click away event. reset menu state
     */
    const handleClickAway = (event) => {
        setMenuOffset(-10000)
        setSubList([])
        setActiveItem({})
    }
    /**
     * Handle event to open second level menu and recalculate position
     * @param parent
     * @return {boolean}
     */
    const handleOpenSubRoutesList = (parent) => {
        // hide submenu and restore original position
        if( subList.length )  {
            setSubList([])
            setMenuOffset(originalPosition)
            setActiveItem({route: {}, parent})
            return false
        }
        // store parent item
        setParentItem(parent)
        // get page client width
        const clientWidth = document.body.clientWidth;
        // calculate menu position relatively to the menu item position
        const menuPosition = (menuOffset - originalOffsetWidth) + (menuDropDown.current.offsetWidth * 2)
        // calculate right offset value
        const offsetRight = (menuPosition > clientWidth ) ? (menuPosition - clientWidth) + 15 : 0;
        // set sub items block to the calculated position
        if('subItems' in parent) {
            setSubList(parent.subItems)
            setMenuOffset((menuOffset - originalOffsetWidth) - offsetRight)
        }
    }
    useEffect(() => {
        findActiveRoute(route)
    }, [])

    /**
     * In case if active item was found need to open second submenu immediately
     */
    useEffect(() => {
        if(activeItem.parent) handleOpenSubRoutesList(activeItem.parent)
    }, [activeItem])

    /**
     *
     */
    const isParentItemActive = (item) => {
        return activeItem.parent && activeItem.parent.label === item.label
    }

    /**
     * Menu Item  render block
     * @param props
     * @return {*}
     * @constructor
     */
    const MenuItem = (props) => {
        const {
            item,
            className
        } = props
        if (!item.link) {
            return <div className={className}><span>{item.label}</span> <div className="menu-list-ico"></div></div>
        }  else {
            return <NavLink onClick={() => handleClickAway()} className={`${className ? className : 'link-item'}`} exact  to={item.link}>{item.label}</NavLink>
        }
    }

    /**
     * Parent List drop down render block
     * @return {unknown[]}
     * @constructor
     */
    const BuildParentItem = () => {
       return  route.items.map((item, index) => {
            return <div
                className={`menu-list-item ${isParentItemActive(item) ? 'active' : '' }`}
                key={`${item.label}_${index}`}
                onClick={()=>handleOpenSubRoutesList(item)}
            >
                <MenuItem className={`${item.subItems && item.subItems.length ? 'parent-link': ''}`} item={item} />
            </div>
        })
    }

    /**
     * Sub list drop down render block
     * @return {unknown[]}
     * @constructor
     */
    const BuildSubListItems = () => {
        return subList.map((item, index) => {
            return <div className="menu-list-item" key={`${item.label}_${index}`}>
                <MenuItem item={item} />
            </div>
        })
    }
    /**
     * Nested Menu render block
     * @return {*}
     * @constructor
     */
    const NestedMenu = () => {
        return <>
            <div ref={menuDropDown}  className="menu-list-wrapper" style={{left: menuOffset +'px'}}>
                <div className="menu-list">
                    <div className={`menu-list-items ${activeItem.parent && route.type !== 'single' ? 'active-list' : ''}`}>
                           <BuildParentItem />
                    </div>
                    <div className={`menu-list-sub ${ subList.length ? 'show' : 'hide' }` }>
                        <div className="sub-list-header">{parentItem.label}</div>
                        <div className="menu-list-items">
                            <BuildSubListItems />
                        </div>
                    </div>
                </div>
            </div>
            <span
                onClick={ () => {
                    if (route.link) history.push(route.link);
                }}
                onMouseEnter={(e) =>handleOpenMenu(e)}
                onTouchStart={(e) =>handleOpenMenu(e)}
                className={`label ${setParentActive(route).length || location.pathname === route.link ? 'is-active': ''}`}
            >
                {route.label}
            </span>
        </>
    }
    /**
     * Menu wrapper render block
     * @return {*}
     * @constructor
     */
    const Menu = () => {
        if (route.items && route.items.length) {
            return <NestedMenu />
        }  else {
            return <span className={`label ${route.link === location.pathname ? 'is-active':''}`}><NavLink exact  to={route.link}>{route.label}</NavLink></span>
        }
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="menu-group">
                <Menu />
            </div>
        </ClickAwayListener>
    )
}

export default NestedMenu;
