import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const SubHeaderBlock = ({ fields }) => (
  <div className="sub-header-block">
      <div className="sub-header-wrapper text-content-layout">
          <Text tag="div" className="main" field={fields.mainText} />
          <Text tag="div" className="sub-block" field={fields.secondaryText} />
      </div>
  </div>
);

export default SubHeaderBlock;
