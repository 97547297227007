import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ServiceCard from '../ServiceCard';

const ServiceCardBlock = (props) => {
  const { heading, serviceCards, rootClassName, subHeading, cardsListClassName, isSingleType, footerText } = props.fields;
  return (
    <div className={(isSingleType && isSingleType.value ? 'service-cards-block-single' : 'service-cards-block') + ' ' + rootClassName.value}>
        {!!heading && heading.value && <div className="block-header">
          <Text tag="div" className="inc-h1" field={heading} />
        </div>}
        <Text tag="div" className="sub-header" field={subHeading} />
        <div className={cardsListClassName.value}>
          {serviceCards &&
            serviceCards.map((listItem, index) => (
              <ServiceCard
                    isSingleType={isSingleType && isSingleType.value}
                    key={index}
                    icon={(listItem.fields.iconClassName && listItem.fields.iconClassName.value)}
                    header={listItem.fields.header}
                    text={listItem.fields.text}
                    bg={(listItem.fields.backgroundColor && listItem.fields.backgroundColor.value)}
                    color={(listItem.fields.color && listItem.fields.color.value)}
                    linkTo={listItem.fields.linkTo}
                    btnIconColor={(listItem.fields.buttonIconColor && listItem.fields.buttonIconColor.value)}
                />
            ))}
        </div>
        {!!footerText && <Text tag="div" className="block-regular font-regular text-align-center m-b-100" field={footerText} />}
    </div>
  );
};

export default ServiceCardBlock;
