import React, {useState} from 'react';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from './menu-item'
// import { routesMap } from '../../constants/routes-map'
import './styles.scss';
import MobileMenu from './mobile-menu';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';

const MenuItemsQuery = gqlLoader('./query.graphql');

const Menu = (props) => {

    const graphQLResult = props.connectedQuery;

    // Async loading and error handling
    // Remember to never return null from a JSS component when loading,
    // this will break Experience Editor.
    const { error, loading, datasource } = graphQLResult;

    let routesMap = {};

    if (!error && !loading && datasource && datasource.routeRoot && datasource.routeRoot.targetItem.children && datasource.routeRoot.targetItem.children.results) {
        routesMap = Object.values(datasource.routeRoot.targetItem.children.results).map(route => {
            return {
                showInMenu: !!route.showInMenu.value,
                label: route.menuTitle.value,
                link: !!route.isNotNavigable.value ? '' : route.url.path,
                items: route.children && route.children.results ?
                    Object.values(route.children.results).map(childRoute => {
                        return {
                            showInMenu: !!childRoute.showInMenu.value,
                            label: childRoute.menuTitle.value,
                            link: !!childRoute.isNotNavigable.value ? '' : childRoute.url.path,
                        }
                    }).filter(x => x.showInMenu) :
                    []
            }
        }).filter(x => x.showInMenu);

        routesMap.unshift({
            label: datasource.routeRoot.targetItem.menuTitle.value,
            link: datasource.routeRoot.targetItem.url.path
        });
    }

    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
    const [submenu, setSubmenu] = useState({});

    function handleMobileMenuToggle(route = {}) {
        const maskNode = props.mask.current;
        if('items' in route && route.items.length && !Object.keys(submenu).length) {
            setSubmenu(route)
        } else {
            if(!isMobileMenuOpened) {
                document.body.style.overflow = 'hidden';
                maskNode.style.display = 'block';
                setIsMobileMenuOpened(true)
            } else {
                document.body.style.overflow = null;
                maskNode.style.display = 'none';
                setIsMobileMenuOpened(false)
                setSubmenu({})
            }
        }
    }

    function onBack() {
        setSubmenu({})
    }

    return (
        <>
            <Hidden xsDown implementation="css">
                <div className="header-navigation">
                    {Object.keys(routesMap).map(route => {
                        return <MenuItem key={route} route={routesMap[route]} />
                    })}
                </div>
            </Hidden>
            <Hidden only={['sm', 'lg', 'xl', 'md']} implementation="css" className="navigation-container">
                <div className="header-navigation">
                    <IconButton
                        onClick={()=>handleMobileMenuToggle()}
                        className={`header-bar ${isMobileMenuOpened ? 'closed' : 'opened'}`}
                        style={{ color: 'white'}}
                    >
                        <Icon className="fas fa-bars" />
                    </IconButton>
                    <MobileMenu
                        menuState={isMobileMenuOpened}
                        onMobileMenuToggle={handleMobileMenuToggle}
                        onBack={onBack}
                        routesMap={routesMap}
                        submenuState={submenu}
                    />
                </div>
            </Hidden>
        </>
    )
};

export default GraphQLData(MenuItemsQuery, { name: 'connectedQuery' })(
    Menu
);
