import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import BrandReferenceCard from '../BrandReferenceCard';

const BrandReferenceCardBlock = (props) => {
  const { heading, brandReferenceCards, rootClassName, cardsListClassName, } = props.fields;
  const [displayIndex, setDisplayIndex] = useState(0);
  const [instancesPerRow, setInstancesPerRow] = useState(1);
  const [fadeStatus, setFadeStatus] = useState('faded-in'); // Initial fade status
  const brandReferenceCards1 = [];
  const brandReferenceCards2 = [];

  const totalCards = brandReferenceCards.length;
  let startIndex = 0;

  while (startIndex < totalCards) {
    const endIndex1 = Math.min(startIndex + instancesPerRow, totalCards);
    const endIndex2 = Math.min(startIndex + instancesPerRow * 2, totalCards);
    brandReferenceCards.slice(startIndex, endIndex1).forEach(item => {
      brandReferenceCards1.push(item);
    });

    if((startIndex + instancesPerRow) < endIndex2) {
      brandReferenceCards.slice(startIndex + instancesPerRow, endIndex2).forEach(item => {
        brandReferenceCards2.push(item);
      });
    }
    startIndex = endIndex2;
  }

  useEffect(() => {
    // Function to calculate number of instances per row based on screen width
    const calculateInstancesPerRow = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1400) {
        // Desktop view: 3 instances per row
        return 5;
      } else if (screenWidth >= 1180) {
        // Desktop view: 3 instances per row
        return 4;
      } else if (screenWidth >= 924) {
        // Desktop view: 3 instances per row
        return 3;
      } else if (screenWidth >= 668) {
        // Tablet view: 2 instances per row
        return 2;
      } else {
        // Mobile view: 1 instance per row
        return 1;
      }
    };

    // Update instances per row on window resize
    const handleResize = () => {
      setInstancesPerRow(calculateInstancesPerRow());
    };

    // Initial calculation
    setInstancesPerRow(calculateInstancesPerRow());

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function for removing event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeStatus('fade-out');
      setTimeout(() => {
        setDisplayIndex((prevIndex) => (prevIndex + instancesPerRow) % (brandReferenceCards1.length));
        setFadeStatus('fade-in'); // Change fade status to 'fade-in' just before rendering
      }, 1000); // Adjust the delay as needed
    }, 7000); // Change the interval duration as needed

    return () => clearInterval(interval);
  }, [brandReferenceCards1.length, instancesPerRow]);

  return (
    <div className={('brand-reference-card-block') + ' ' + rootClassName.value}>
      {!!heading && heading.value && (
        <div className="block-header">
          <Text tag="div" className="inc-h1" field={heading} />
        </div>
      )}
      <div className={cardsListClassName.value}>
        {brandReferenceCards1 &&
          brandReferenceCards1.slice(displayIndex, displayIndex + instancesPerRow).map((listItem, index) => (
            <div key={index} className={`fade-item ${fadeStatus}`}>
              <BrandReferenceCard
                referenceImage={listItem.fields.referenceImage && listItem.fields.referenceImage.value}
                referenceName={listItem.fields.referenceName && listItem.fields.referenceName.value}
                linkTo={listItem.fields.linkTo}
              />
          </div>
          ))}
      </div>
      <div className={cardsListClassName.value}>
        {brandReferenceCards2 &&
          brandReferenceCards2.slice(displayIndex, displayIndex + instancesPerRow).map((listItem, index) => (
            <div key={index} className={`fade-item ${fadeStatus}`}>
              <BrandReferenceCard
                referenceImage={listItem.fields.referenceImage && listItem.fields.referenceImage.value}
                referenceName={listItem.fields.referenceName && listItem.fields.referenceName.value}
                linkTo={listItem.fields.linkTo}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default BrandReferenceCardBlock;
