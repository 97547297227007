import React from 'react';
import GoogleMapReact from 'google-map-react';
import { MapOptions } from '../../constants';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const OfficeMap = ({ fields }) => {
  const mapProps = {
      center: {
          lat: parseFloat(fields.centerLat.value),
          lng: parseFloat(fields.centerLng.value)
      },
      zoom: parseInt(fields.zoom.value)
  }

  const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
          position: { lat: mapProps.center.lat, lng: mapProps.center.lng },
          map,
          title: 'Include office'
      });
      return marker;
  };

  function createMapOptions () {
      return MapOptions;
  }
  return (
  <div className="map m-b-100">
      <Text tag="span" className="map-heading" field={fields.heading} />
      <GoogleMapReact
          bootstrapURLKeys={{ key: fields.apiKey.value}}
          defaultCenter={mapProps.center}
          defaultZoom={mapProps.zoom}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      >
      </GoogleMapReact>
  </div>
)
}

export default OfficeMap;
