import React, { useEffect, useState, useRef  } from 'react';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './styles.scss';
import { Link } from 'react-router-dom';

const TopBar = (props) => {
    const [isScroll, setIsScroll] = useState(false);
    const mask = useRef(null)
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs')) ? 'xs' : false;
    const md = useMediaQuery(theme.breakpoints.down('md')) ? 'md' : false;

    const scrollValues = {
        xs : 50,
        md: 90,
        lg: 170
    }

    const FLIP_HEADER_SCROLL_VALUE = scrollValues[xs || md || 'lg'];

    const handleScroll = (e) => {
        setIsScroll ((window.scrollY > FLIP_HEADER_SCROLL_VALUE) ? true : false);
    }

    useEffect( () => {
        document.addEventListener('scroll', handleScroll);
        return () => document.removeEventListener('scroll', handleScroll);
    });

    const setScrollCLass = () => {
        return (isScroll ) ? 'top-bar scroll' : 'top-bar'
    }

    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                className={setScrollCLass()}
                justifyContent="space-between"
            >
                <Link to="/" className="logo" />
                <Box className="top-menu">
                    <Placeholder
                        rendering={props.rendering}
                        name="jss-menu-placeholder"
                        mask={mask}
                    />
                </Box>
                <div ref={mask} className="mask"/>
            </Box>
        </>
        )

}

export default TopBar;
