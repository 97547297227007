import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';

const IncLink = (props) => {
    const {
        onMobileMenuToggle,
        path,
        className
    } = props

    let location = useLocation()

    const setActive = () => {
        return (path.link === location.pathname) // (path.items && path.items.filter( i => i.link === location.pathname).length)
    }

    function LinkRenderer () {
        if(path.items && path.items.length && className !== 'sub-items-overview') {
            return <div
                    className="master-link"
                    onClick={()=>onMobileMenuToggle(path)}
                >
                    {path.label}
                <Icon className="fas fa-angle-right" style={{ color: '#B2B2B2'}} />
            </div>
        } else {
            return  <Link className={className} onClick={()=>onMobileMenuToggle(path)} to={path.link}>
                {path.label} {className === 'sub-items-overview' ? 'Overview' : ''}
            </Link>
        }
    }

    return (
        <div className={`menu-item ${setActive() ? 'active': ''}`}>
           <LinkRenderer />
        </div>
    )
}

export default IncLink;
