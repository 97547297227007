import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const FooterContainer = (props) => (
  <Placeholder
    rendering={props.rendering}
    name="jss-footer-placeholder"
  />
);

export default FooterContainer;
