import React from 'react';
import HexagonIcon from '../HexagonIcon';
import './styles.scss';

const InfoCardSimple = (props) => {
    const {
        title,
        text,
    } = props;
    return (
        <div className="info-card-simple font-regular">
            <div className="icon-holder"><HexagonIcon className="simple" icon="fas fa-check" color="#8ED024" /></div>
            <div className="title block-regular">{title}</div>
            <div className="text block-extra-light">{text}</div>
        </div>
    )
}

export default InfoCardSimple;
