import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import IncLink from './inc-link'
import './styles.scss';
import {HashLink} from 'react-router-hash-link';
import { scrollWidthOffset } from '../../helpers/utils'
const MobileMenu = (props) => {
    const {
        menuState,
        onMobileMenuToggle,
        onBack,
        routesMap,
        submenuState
    } = props

    useEffect( () => {
        setTimeout(() => document.body.className = '', 1000);
    });

    function isSubListActive () {
        return submenuState && submenuState.items;
    }

    const MenuItems = () => {
        if(isSubListActive()) {
            let subMenuItems = [];
            if(submenuState.link) {
                subMenuItems.push( <IncLink
                    className="sub-items-overview"
                    key={submenuState.label}
                    onMobileMenuToggle={onMobileMenuToggle}
                    path={submenuState}
                />)
            }
            subMenuItems = [...subMenuItems, ...submenuState.items.map(route => {
                return <IncLink
                    className="sub-items"
                    key={route.label}
                    onMobileMenuToggle={onMobileMenuToggle}
                    path={route}
                />
            })]
            return subMenuItems
        } else {
            return Object.keys(routesMap).map(route => {
                return <IncLink
                    key={route}
                    onMobileMenuToggle={onMobileMenuToggle}
                    path={routesMap[route]}
                />
            })
        }
    }

    return (
        <div className="mobile-navigation">
            <Hidden only={['sm', 'lg', 'xl', 'md']}>
                <div className={`mobile-menu ${menuState ? 'opened' : 'closed'}`}>
                    <div className="nav-buttons">
                        <div className={`return ${!isSubListActive() ? 'hide' : ''}`}>
                            <IconButton onClick={()=>onBack()}>
                                <Icon className="fas fa-angle-left" style={{ color: '#808080'}} />
                            </IconButton>
                        </div>
                        <div className={`selected-header ${!isSubListActive() ? 'hide' : ''}`}>
                            {submenuState.label}
                        </div>
                        <div className="close">
                            <IconButton onClick={()=>onMobileMenuToggle()}>
                                <Icon className="fas fa-times" style={{ color: '#808080'}} />
                            </IconButton>
                        </div>
                    </div>
                    <div className="menu-items">
                        <MenuItems />
                        <div className={`sub-menu ${isSubListActive() ? 'hide' : ''}`}>
                            <Link onClick={()=>onMobileMenuToggle()} to="/legal/terms">
                                Terms & conditions
                            </Link>
                            <Link  onClick={()=>onMobileMenuToggle()} to="/legal/privacy">
                                Privacy statement
                            </Link>
                            <HashLink smooth onClick={()=>onMobileMenuToggle()} to="/legal/privacy#cookies" scroll={el => scrollWidthOffset(el)}>Cookie policy</HashLink>
                        </div>
                    </div>
                </div>
            </Hidden>
        </div>
    )
}

export default MobileMenu;
