import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import InfoCardSimple from '../ServiceCard/InfoCardSimple';

const InfoCardBlock = ({fields}) => {
  return (
    <div className="info-cards-block m-t-55">
      <Text tag="div" className="sub-header to-uppercase" field={fields.heading} />
      <div className="cards-holder m-t-75">
          {!!fields.infoCards && fields.infoCards.map( (listItem, index) => (
              <InfoCardSimple key={index} title={listItem.fields.title.value} text={listItem.fields.text.value} />
            )
          )}
      </div>
    </div>
  );
};

export default InfoCardBlock;
