import React from 'react';
import GoogleMapReact from 'google-map-react';
import { MapOptions } from '../../constants';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const OfficeDoubleMap = ({ fields }) => {
    const mapProps1 = {
        center: {
            lat: parseFloat(fields.centerLat1.value),
            lng: parseFloat(fields.centerLng1.value)
        },
        zoom: parseInt(fields.zoom1.value)
    };

    const mapProps2 = {
        center: {
            lat: parseFloat(fields.centerLat2.value),
            lng: parseFloat(fields.centerLng2.value)
        },
        zoom: parseInt(fields.zoom2.value)
    };

    const renderMarkers = (map, maps, mapsProps) => {
        let marker = new maps.Marker({
            position: { lat: mapsProps.center.lat, lng: mapsProps.center.lng },
            map,
            title: 'Include office'
        });
        return marker;
    };

    function createMapOptions() {
        return MapOptions;
    }
    return (
        <div className="map m-b-100">
            <Text tag="span" className="map-heading" field={fields.heading} />
            <div className="maps-wrapper">
                <div className="doubleMap">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: fields.apiKey.value }}
                        defaultCenter={mapProps1.center}
                        defaultZoom={mapProps1.zoom}
                        options={createMapOptions}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps, mapProps1)}
                    >
                    </GoogleMapReact>
                </div>
                <div className="doubleMap">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: fields.apiKey.value }}
                        defaultCenter={mapProps2.center}
                        defaultZoom={mapProps2.zoom}
                        options={createMapOptions}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps, mapProps2)}
                    >
                    </GoogleMapReact>
                </div>
            </div>
        </div>
    )
}

export default OfficeDoubleMap;
