import React from 'react';
import './styles.scss';

const HexagonIcon = (props) => {
    const {
        bg,
        icon,
        color,
        className = ''
    } = props;
    return (
        <div className={`hexagon-icon-holder ${className}`} style={{background : bg}}>
            <i className={icon} style={{color: color}}></i>
        </div>
    )
}

export default HexagonIcon;
