import { Form } from '@sitecore-jss/sitecore-jss-react-forms';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { sitecoreApiHost, sitecoreApiKey } from '../../temp/config';
import { createDefaultFieldFactory } from '@sitecore-jss/sitecore-jss-react-forms';
import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import IncludeButton from '../Button';

const JssReactForm = ({ fields, history, onSubmit }) => {

    const defaultFieldFactory = createDefaultFieldFactory();
    defaultFieldFactory.setComponent(FieldTypes.Button, (props) => (
        <div className="btn-holder">
            <IncludeButton
                textColor="#242528"
                icon="fas fa-paper-plane"
                text="send"
                type="submit"
                {...props}
            />
        </div>
    ));
    defaultFieldFactory.setComponent(FieldTypes.Section, ({field, fieldFactory}) => (
        <div className={field.model.cssClass}>
            {field.fields.map(fieldFactory)}
        </div>
    ));

    const ErrorComponent = (props) => {
        return (<div className="formErrorsList">
             {props.formErrors.map((error, index) =>{
                return <h3 key={`formError-${index}`}>{error}</h3>
            })}
            {!!props.fieldErrors && !!props.fieldErrors.length && <div className="fieldErrors">
                {props.fieldErrors.map((error, index) =>{
                    return <div key={`formError-${index}`}>{error.state.errors.map((message) => <p key={`fieldError-${message}`}>{message}</p>)}</div>
                })}
            </div>}
        </div>)
    };

    const LabelComponent = (props) => {
        return <></>
    }

    return (<Form
        className={fields.metadata.cssClass}
        form={fields}
        labelComponent={LabelComponent}
        sitecoreApiHost={sitecoreApiHost}
        sitecoreApiKey={sitecoreApiKey}
        fieldFactory={defaultFieldFactory}
        onRedirect={(url) => history.push(url)}
        errorComponent={ErrorComponent}
    />)
};

export default withRouter(JssReactForm);
