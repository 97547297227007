import React from 'react';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { Image } from '@sitecore-jss/sitecore-jss-react';

const BrandReferenceCard = (props) => {

    const {
        referenceImage,
        referenceName,
        linkTo,
    } = props;

    const history = useHistory()

    return (
        <div className="brand-reference-card">
            <div className="brand-reference-card-content">
                {linkTo && linkTo.value && linkTo.value.href ?
                <a href={linkTo.value.href} title={referenceName}>
                    <Image  field={referenceImage} alt={referenceName}
                        editable={false}
                        height="99"
                        width="174"
                        className="img-fluid"
                      />
                </a> : <Image  field={referenceImage} alt={referenceName}
                        editable={false}
                        height="99"
                        width="174"
                        className="img-fluid"
                      />}
            </div>
        </div>
    )
}

export default BrandReferenceCard;
