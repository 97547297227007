import React from 'react';
import Helmet from 'react-helmet';
import TopBar from './components/TopBar';
import Header from './components/Header';

const NotFound = ({ context = { site: { name: '' }, language: '' } }) => (
  <div className="main">
      <TopBar/>
      <div className="main-page-layout">
          <Helmet
              title="Include Agency | Sitecore, .NET and consultancy specialists"
              meta={[
                  {name:'robots', content:'noindex, nofollow'}
              ]}
          />
          <Header fields={({
            description: {value: 'We are sorry but the page you were requesting was not found. Please use the navigation options above to find the information you were looking for'},
            title: {value: 'Page Not Found'}
            })}/>
      </div>
    </div>
);

export default NotFound;
