import React from 'react';
import './styles.scss';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

const ContactForm = ({ fields, rendering }) => {
    return (
        <div className="contact-form">
            {!!fields.decorationTopLine && !!fields.decorationTopLine.value && <div className="deco-line-1" />}
            {!!fields.heading && <Text tag="div" className="header-cf inc-h1" field={fields.heading} />}
            {!!fields.subHeading && <Text tag="div" className="sub-header" field={fields.subHeading} />}

            <Placeholder
                rendering={rendering}
                name="jss-form"
            />
        </div>
    )
}

export default ContactForm;
