import React from 'react';
import IncludeButton from '../Button';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';
import './styles.scss';

const Header = (props) => {
    const {fields} = props
    let history = useHistory();

    const safeText = fields.title && fields.title.value && String(fields.title.value);

    return (
        <div className={`header ${(fields.className && fields.className.value) || ''}`}>
                <div className="content">
                    <h1 className="page-header">
                        {safeText.toUpperCase()}
                    </h1>
                    {!!fields.description && !!fields.description.value.length && <Text tag="h2" className="desc" field={fields.description} />}
                    {!!fields.actionButtonText && !!fields.actionButtonText.value.length && <div className="action">
                        <IncludeButton
                            text={fields.actionButtonText.value}
                            classType="header-type"
                            textColor="white"
                            onClick={() => history.push((fields.actionButtonLink && fields.actionButtonLink.value.href))}
                        />
                    </div>}
                </div>
        </div>
    )
}

export default Header;
